var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_vm._l((_vm.data.data),function(col,index){return _c('b-col',{key:index,attrs:{"lg":col['lg'] || 6,"sm":col['sm'] || 12,"xs":col['sm'] || 12,"md":col['sm'] || 6}},[(typeof col.show !== 'undefined' ? col.show : true)?_c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":col['labelCol'] || 3,"label-cols-md":col['labelColMd'] || 4,"label-cols-lg":col['labelColLg'] || 3,"label":_vm.getLabel(col),"label-for":col['field_id'] || col['name']}},[_c(col['type'],{tag:"component",attrs:{"prop-data":Object.assign(col, {
                    id: col['field_id'] || col['name'],
                    value: _vm.setValue (col),
                    options: col['options'],
                    selectTitle: col['selectTitle'],
                    label: _vm.getLabel(col),
                    config: col['config'],
                    onChange: col['onChange']
                })},on:{"returned-value":function($event){return _vm.setData(col, $event)},"picker-instance":function($event){return _vm.$emit('picker-instance', $event)},"field-manipulated":function($event){return _vm.$emit('field-manipulated', $event)}}})],1):_vm._e()],1)}),(_vm.data['inlineButton'])?_c('b-col',[_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":_vm.returnData}},[_vm._v(" "+_vm._s(_vm.data['buttonLocale'] ? _vm.$t(_vm.data['buttonLocale']) : _vm.data['buttonText'])+" ")])],1):_vm._e()],2),(typeof _vm.data['inlineButton'] === 'undefined' || !_vm.data['inlineButton'])?_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":_vm.returnData}},[_vm._v(" "+_vm._s(_vm.data['buttonLocale'] ? _vm.$t(_vm.data['buttonLocale']) : _vm.data['buttonText'])+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }