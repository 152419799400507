<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('irrigation_task.daily_task_report') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes"
                             @returned-data="searchData"
                             @picker-instance="getDateInstance"
                />
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card v-if="listData.length > 0">
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('irrigation_task.task_report') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" @click="pdfExport" class="mr-2">
                        {{  $t('globalTrans.print') }}
                        </b-button>
                        <export-excel
                            class="btn btn-success mr-2"
                            :data="dataCustomize"
                            :fields= "json_fields"
                            :title="$t('irrigation_task.task_report')"
                            worksheet="$t('irrigation_task.task_report')"
                            name="task-report.xls">
                            {{ $t('globalTrans.export_excel') }}
                        </export-excel>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col>
                                    <list-report-head :base-url="irriSchemeServiceBaseUrl" uri="report-heading/detail" :org-id="search.org_id" :loading-state="loadingState">
                                    {{ $t('irrigation_task.task_report')}}
                                    </list-report-head>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template #thead-top>
                                            <b-tr>
                                                <b-th colspan="6" class="text-center font-size-18">{{ $t('irrigation_task.task')}}</b-th>
                                                <b-th colspan="4" class="text-center font-size-18">{{ $t('irrigation_task.task_report')}}</b-th>
                                                <b-th></b-th>
                                            </b-tr>
                                        </template>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + 1) }}
                                        </template>
                                        <template v-slot:cell(org_name)="data">
                                            {{ getOrgName(data) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                                        </template>
                                        <template #cell(task_report_attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download>Link</a>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(task_assign_attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download>Link</a>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(task_id)="data">
                                            <div>{{ $n(data.value) }}</div>
                                        </template>
                                        <template #cell(assign_username)="data">
                                            <div>{{ data.value }}</div>
                                        </template>
                                        <template #cell(task_date)="data">
                                            <div>{{ data.value | dateFormat }}</div>
                                        </template>
                                        <template #cell(task_reports_note)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_reports_note_bn)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { dailyTaskReportsList } from '../../api/routes'
import { Common, helpers, List } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { dateFormat } from '@/Utils/fliter'

Vue.use(excel)

const excelColumn = {
    Organization: 'org',
    'Task ID': 'taskId',
    'Task Name': 'taskName',
    Responsible: 'respon',
    Schedule: 'sch',
    'Task Note': 'taskNote',
    'Task Calendar Date': 'taskCD'
}
const excelColumnBn = {
    সংগঠন: 'org',
    'কার্যের আইডি': 'taskId',
    'কার্যের নাম': 'taskName',
    দায়বদ্ধ: 'respon',
    সময়সূচী: 'sch',
    'কার্যের নোট': 'taskNote',
    'কার্য ক্যালেন্ডার তারিখ': 'taskCD'
}
export default {
    mixins: [List, Common],
    components: {
        FormSearch,
        ListReportHead
    },
    data () {
        return {
            irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
            load: false,
            taskId: null,
            search: {
                farmer_name: '',
                mobile_no: '',
                meter_reading_before: '',
                meter_reading_after: '',
                pump_running_time: '',
                irrigation_area: '',
                working_date: '',
                task_date: ''
            },
            testId: 0,
            rows: [],
            defaultNull: '',
            taskReport: {
                attachment: []
            },
            taskStatusList: [],
            dateInstanceStore: [],
            dateInstance: []
        }
    },
    computed: {
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            const listData = this.listData
            listData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    // item.org = item.org_name_bn
                    item.taskId = item.task_id
                    item.taskName = item.task_name_bn
                    item.respon = item.assign_user_id
                    item.sch = item.schedule
                    item.taskNote = item.task_reports_note_bn
                    item.taskCD = item.task_date
                } else {
                    // item.org = item.org_name
                    item.taskId = item.task_id
                    item.taskName = item.task_name
                    item.respon = item.assign_username
                    item.sch = item.schedule
                    item.taskNote = item.task_reports_note
                    item.taskCD = item.task_date
                }
                return Object.assign({}, item)
            })
            return listData
        },
        taskTypeList () {
            return this.$store.state.commonObj.irrigationTaskTypeList
        },
        orgList () {
            return this.$store.state.commonObj.organizationProfileList
        },
        formTitle () {
            return this.$t('irrigation_task.task_report') + ' ' + (this.testId === 0) ? this.$t('globalTrans.entry') : this.$t('globalTrans.modify')
        },
        listData () {
            return this.$store.state.IrriTask.taskReportsList
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('org_pro.organization'), class: 'text-left' },
                { label: this.$t('irrigation_task.task_id'), class: 'text-left' },
                { label: this.$t('irrigation_task.task_name'), class: 'text-left' },
                { label: this.$t('irrigation_task.responsible'), class: 'text-left' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('globalTrans.schedule'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('irrigation_task.note'), class: 'text-left' },
                { label: this.$t('irrigation_task.task_calendar_date'), class: 'text-left' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'task_id' },
                { key: 'task_name' },
                { key: 'assign_username' },
                { key: 'task_assign_attachment' },
                { key: 'schedule' },
                { key: 'status' },
                { key: 'task_report_attachment' },
                { key: 'task_reports_note' },
                { key: 'task_date' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'org_name_bn' }
                keys[3] = { key: 'task_name_bn' }
                keys[9] = { key: 'task_reports_note_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        searchAttributes () {
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'farmer_name',
                        type: 'Input',
                        label: 'warehouseFarmer.farmer_name'
                    },
                    {
                        name: 'mobile_no',
                        type: 'Input',
                        label: 'globalUserData.mobile_no'
                    },
                    {
                        name: 'meter_reading_before',
                        type: 'Input',
                        label: 'warehouseFarmer.meterReadingBefore'
                    },
                    {
                        name: 'meter_reading_after',
                        type: 'Input',
                        label: 'warehouseFarmer.meterReadingAfter'
                    },
                    {
                        name: 'pump_running_time',
                        type: 'Input',
                        label: 'warehouseFarmer.pumpRunnigTime'
                    },
                    {
                        name: 'irrigation_area',
                        type: 'Input',
                        label: 'warehouseFarmer.irriArea'
                    },
                    {
                        name: 'working_date',
                        type: 'Datepicker',
                        label: 'warehouseFarmer.workingDate'
                    },
                    {
                        name: 'task_date',
                        type: 'Datepicker',
                        label: 'warehouseFarmer.submissionDate'
                    }
                ]
            }
        }
    },
    watch: {
        load (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        }
    },
    created () {
        this.$store.dispatch('IrriTask/irriTaskReportsList', [])
    },
    methods: {
        getOrgName (data) {
          return this.$i18n.locale === 'en' ? data.item.org_name : data.item.org_name_bn
        },
        getDateInstance (payload) {
            this.dateInstanceStore.push(payload)
            if (this.dateInstanceStore.length > 1) {
                this.dateInstance = this.dateInstanceStore
            }
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                editable: false
            }
        },
        async searchData (payload) {
            this.search = payload
            await this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.load = true
            RestApi.getData(irriSchemeServiceBaseUrl, dailyTaskReportsList, params).then((response) => {
                this.load = false
                if (response.success) {
                    /* const dataList = this.mixinLoadData(irriSchemeServiceBaseUrl, taskReportsList,
                    'IrriTask/irriTaskReportsList',
                    this.dataList(response))
                    return dataList */
                }
            }).catch((error) => {
                if (error.response) {
                    this.load = false
                }
            })
        },
        dataList (response) {
             if (typeof response.data === 'undefined') {
                return []
            }
            const orgList = this.$store.state.commonObj.organizationProfileList
            let listData = {}
            listData = response.data.map(item => {
                const orgTmp = orgList.find(orgItem => orgItem.value === item.org_id)
                item.org_name = orgTmp.text_en
                item.org_name_bn = orgTmp.text_bn
                const data = {
                    task_assign_attachment: item.task_assign_attachment ? irriSchemeServiceBaseUrl +
                        'uploads/task-assign-tasks/original/' +
                        item.task_assign_attachment : null,
                    task_report_attachment: item.task_reports_attachment ? irriSchemeServiceBaseUrl +
                        'uploads/task-reports/original/' +
                        item.task_reports_attachment : null,
                    task_reports_note: helpers.stringLimit(item.task_reports_note, 10),
                    task_reports_note_bn: helpers.stringLimit(item.task_reports_note_bn, 10)
                }
                return Object.assign({}, item, data)
            })
            delete response.data.data
            listData = Object.assign(response.data, listData)
            return listData
        },
        pdfExport () {
            const rowData = this.getPdfData()
            const labels = [
                { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('globalTrans.organization'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('irrigation_task.task_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('irrigation_task.task_name'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('irrigation_task.responsible'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('globalTrans.schedule'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('irrigation_task.note'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
                { text: this.$t('irrigation_task.task_calendar_date'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
            ]
            rowData.unshift(labels)
            const reportTitle = this.$i18n.locale === 'en' ? 'Task Report' : 'টাস্ক রিপোর্ট'
            const columnWidth = ['7%', '12%', '*', '*', '*', '*', '*', '20%']
            ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWidth)
        },
        getPdfData () {
            const keys = [
                { key: 'serial_no' },
                { key: this.$i18n.locale === 'en' ? 'org_name' : 'org_name_bn' },
                { key: this.$i18n.locale === 'en' ? 'task_id' : 'task_id_bn' },
                { key: this.$i18n.locale === 'en' ? 'task_name' : 'task_name_bn' },
                { key: this.$i18n.locale === 'en' ? 'assign_username' : 'assign_username' },
                { key: this.$i18n.locale === 'en' ? 'schedule' : 'schedule' },
                { key: this.$i18n.locale === 'en' ? 'task_reports_note' : 'task_reports_note_bn' },
                { key: this.$i18n.locale === 'en' ? 'task_date' : 'task_date_bn' }
            ]
            var serial = 0
            const listData = this.listData.map(item => {
                const orgList = this.$store.state.commonObj.organizationProfileList
                const orgTmp = orgList.find(orgItem => orgItem.value === item.org_id)
                item.org_name = orgTmp.text_en
                item.org_name_bn = orgTmp.text_bn
                item.task_id_bn = this.$n(item.task_id)
                item.task_date_bn = dateFormat(item.task_date)

                serial += 13
                return keys.map((keyItem, index) => {
                    if (keyItem.key === 'serial_no') {
                        return { text: this.$n(serial) }
                    }

                    return { text: item[keyItem.key] }
                })
            })

            return listData
        }
    }
}
</script>
